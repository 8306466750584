<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>{{ $t("exam.exams") }}</li>
              <li>{{ $t("exam.edit") }}</li>
            </ul>
          </nav>
        </div>
        <template v-if="errorMessages">
          <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </template>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
        <div class="card mt-4 position-relative">

          <div v-if="loading"
            style="position: absolute; height: 100%; width: 100%; z-index: 999; background: rgba(238, 238, 238, 0.5); display: flex; justify-content: center; align-items: center">
            <div uk-spinner="ratio: 4" class="text-info"></div>
          </div>

          <div class="card-body">
            <h4><i class="uil-question-circle"></i> {{ $t("exam.edit") }}</h4>
            <hr />
            <div class="row">
              <div class="col-xl-9 m-auto">
                <form action="" v-if="exam">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="course_title">
                      {{ $t("exam.name") }}
                      <span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="exam.name" :placeholder="$t('exam.enter_name')"
                        required="" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="passmark">
                      {{ $t("exam.passmark") }}
                      <span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" id="passmark" v-model="exam.passmark"
                        :placeholder="$t('exam.enter_passmark')" required="" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="duration">
                      {{ $t("exam.duration") }}<span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input id="duration" v-model="exam.duration" :placeholder="$t('exam.enter_duration')"
                        class="form-control" required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_mix_questions") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="exam.is_mix_questions"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_mix_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="exam.is_mix_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_right_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="exam.show_right_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_user_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="exam.show_user_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_result") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="exam.show_result"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_compulsory") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="exam.is_compulsory"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <questions modelType="Exam" action="edit" :questions="questions" ref="myEvent"></questions>

                  <!--                  -->
                  <!--                  <div class="form-group row mb-3">-->
                  <!--                    <label class="col-md-3 col-form-label">-->
                  <!--                      {{ $t("exam.question_type") }}-->
                  <!--                      <span class="required">*</span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-9" v-if="exam.selected_questions != undefined">-->


                  <!--                      <select-->
                  <!--                          class="selectpicker2"-->
                  <!--                          v-model="exam.selected_questions"-->
                  <!--                          data-live-search="true"-->
                  <!--                          name="questions"-->
                  <!--                          id="questions"-->
                  <!--                          title="Seçim Yap"-->
                  <!--                          multiple-->
                  <!--                      >-->
                  <!--                        <option-->
                  <!--                            v-for="question in formatQuestionsItem"-->
                  <!--                            :value="question.id"-->
                  <!--                            :selected="exam.selected_questions.includes(question.id)"-->
                  <!--                        >-->
                  <!--                          {{ question.text }}-->
                  <!--                        </option>-->
                  <!--                      </select>-->
                  <!--                      <draggable-->
                  <!--                          class="row flex-column"-->
                  <!--                          :list="exam.selected_questions"-->
                  <!--                          group="widget"-->
                  <!--                      >-->
                  <!--                        <div v-for="(item, index) in selectedQuestions">-->
                  <!--                          <div class="col-12">-->
                  <!--                            <div class="mt-2 selected_questions shadow-sm">-->
                  <!--                              <div-->
                  <!--                                  class="-->
                  <!--                                  d-flex-->
                  <!--                                  justify-content-between-->
                  <!--                                  align-items-center-->
                  <!--                                "-->
                  <!--                              >-->
                  <!--                                {{ item.text }}-->
                  <!--                                <i-->
                  <!--                                    data-v-2cadc991=""-->
                  <!--                                    class="uil-align-justify"-->
                  <!--                                    style="color: rgb(212, 212, 212)"-->
                  <!--                                ></i>-->
                  <!--                              </div>-->
                  <!--                            </div>-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </draggable>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div class="d-flex justify-content-end  align-items-center">
                    <toggle-button :width="120" color="#ae71ff" v-model="exam.is_default"
                      :labels="{ checked: $t('exam.default'), unchecked: $t('exam.not_default') }" />
                  </div>


                  <div class="uk-card-footer float-right pb-0 pr-0">
                    <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                      <i class="icon-feather-arrow-right"></i>{{ $t("general.save") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";
// import questions_module, {
//   BASE_URL as QUESTIONS_BASE_URL,
//   MODULE_NAME as QUESTIONS_MODULE_NAME,
//   GET_ITEMS as QUESTIONS_GET_ITEMS,
//   ITEMS as QUESTIONS_ITEMS,
// } from "@/core/services/store/question.module";

import Questions from "@/view/components/question/Questions";

import module, {
  BASE_URL,
  MODULE_NAME,
  GET_ITEM,
  UPDATE_ITEM,
  ITEM,
  LOADING,
  ERROR,
  SUCCESS
} from "@/core/services/store/exam.module";


// const _QUESTIONS_MODULE_NAME = QUESTIONS_MODULE_NAME;
const _MODULE_NAME = MODULE_NAME;

export default {
  name: "examEdit",
  components: { draggable, Questions },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      questions: [],
      //exam: {},
      //questions: [],
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    // registerStoreModule(_QUESTIONS_MODULE_NAME, questions_module);
    registerStoreModule(_MODULE_NAME, module);
  },
  computed: {
    // formattedQuestions() {


    //   return this.setQuestions();
    // },
    loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    exam: {
      get() {
        let exam = store.getters[MODULE_NAME + "/" + ITEM];
        if (typeof exam.is_default != "undefined") {
          exam.is_default = exam.is_default === 1;
        } else {
          exam.is_default = false;
        }
        (exam.is_mix_questions || exam.is_mix_questions === 1) ? exam.is_mix_questions = true : exam.is_mix_questions = false;
        (exam.is_mix_answers || exam.is_mix_answers === 1) ? exam.is_mix_answers = true : exam.is_mix_answers = false;
        (exam.show_user_answers || exam.show_user_answers === 1) ? exam.show_user_answers = true : exam.show_user_answers = false;
        (exam.show_right_answers || exam.show_right_answers === 1) ? exam.show_right_answers = true : exam.show_right_answers = false;
        (exam.show_result || exam.show_result === 1) ? exam.show_result = true : exam.show_result = false;
        (exam.is_default || exam.is_default === 1) ? exam.is_default = true : exam.is_default = false;
        (exam.is_compulsory || exam.is_compulsory === 1) ? exam.is_compulsory = true : exam.is_compulsory = false;
        return exam;
      },
      set(value) {

      },
    },

    // selectedQuestions: {
    //   get() {
    //     let results = [];
    //     let self = this;
    //     if (this.question_items != null && this.question_items.length && self.exam) {
    //       this.question_items.forEach(currentItem => {
    //         if (self.exam && self.exam.hasOwnProperty('selected_questions') && this.exam.selected_questions && this.exam.selected_questions .includes(currentItem.id)) {
    //           results.push({
    //             id: currentItem.id,
    //             text: currentItem.text,
    //             order: currentItem.id,
    //           })
    //         }
    //       });
    //     }
    //     return results;
    //   },
    //   set(value) {
    //   },
    // },
    // question_items: {
    //   get() {
    //     return store.getters[QUESTIONS_MODULE_NAME + "/" + QUESTIONS_ITEMS];
    //   },
    //   set(value) {
    //   },
    // },
    error: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    success: {
      get() {
        return store.getters[_MODULE_NAME + "/" + SUCCESS];
      },
      set(value) {
      },
    },

    // formatQuestionsItem() {
    //   let results = [];
    //   if (this.question_items != null && this.question_items.length) {
    //     this.question_items.forEach(currentItem => {
    //       results.push({
    //         id: currentItem.id,
    //         text: currentItem.text,
    //         order: currentItem.id,
    //       })
    //     });
    //     return results;
    //   }
    // },
  },
  methods: {
    setQuestions() {
      let self = this;
      let questions = [];
      if (typeof self.exam.questions !== "undefined") {
        self.exam.questions.forEach(question => {
          let options = [];
          question.options.forEach(option => {
            options.push({
              id: option.id,
              name: option.text,
              is_correct: (option.is_correct === 1),
              media: '', //option.question_option_media
              preview: question.question_type_id === 4 || question.question_type_id === 5 ? '<div class="file-info"><img src="' + option.question_option_media + '" alt=""></div>' : null,
            })
          });
          questions.push({
            id: question.id,
            type: question.question_type_id,
            text: question.text,
            media: null,
            options: options,
            preview: question.question_type_id === 3 || question.question_type_id === 5 ? '<div class="file-info"><img src="' + question.question_media + '" alt=""></div>' : null,
            audioPreview: question.question_type_id === 7 ? '<div class="file-info"><div>Ses Dosyası Adı: <span>' + question.media + '</span></div></div>' : null,
            videoPreview: question.question_type_id === 8 ? '<div class="file-info"><div>Video Dosyası Adı: <span>' + question.media + '</span></div></div>' : null,
            previewReply: [],
          });
        });
      }
      this.questions = questions;
    },
    saveForm() {
      let self = this;
      let validated = true;

      let formData = new FormData();
      let myQuestions = self.$refs.myEvent.handleNewQuestions();


      if (self.exam.name == null || self.exam.name.length < 3) {
        self.scrollToTop();
        validated = false;
        self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("exam.name")));
      }

      if (self.exam.duration == null || +self.exam.duration < 1) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.duration")));
        self.scrollToTop();
        validated = false;
      }

      if (myQuestions.length === 0) {
        self.scrollToTop();
        validated = false;
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.question")));
      }

      //formData.append('questions', JSON.stringify(myQuestions));


      myQuestions.forEach((question, questionIndex) => {
        let isCorrectCount = 0;
        let hasNullName = false;
        let hasNullMedia = false;

        if (question.text === '') {
          validated = false;
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.text")));
          self.scrollToTop();
          return false;
        }

        question.options.forEach(option => {
          if (option.name === '') {
            hasNullName = true;
          }

          if (option.is_correct) {
            isCorrectCount++
          }

          if (typeof option.media === "undefined") {
            hasNullMedia = true;
          }
        });

        if (hasNullName && (question.type !== 4 && question.type !== 5)) {
          validated = false;
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_name")));
          self.scrollToTop();
          return false;
        }

        if (isCorrectCount === 0) {
          validated = false;
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.required.true_count")));
          self.scrollToTop();
          return false;
        }

        if (+question.type === 1 && isCorrectCount > 1) {
          validated = false;
          self.scrollToTop();
          self.errorMessages.push(self.$t('question.required.true_count'));
          self.scrollToTop()
          return false;
        }

        if (hasNullMedia && (question.type === 4 || question.type === 5)) {
          validated = false;
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_media")));
          self.scrollToTop();
          return false;
        }

        formData.append('questions[' + questionIndex + '][id]', question.id);
        formData.append('questions[' + questionIndex + '][type]', question.type);
        formData.append('questions[' + questionIndex + '][text]', question.text);
        formData.append('questions[' + questionIndex + '][media]', question.media);
        //formData.append('questions['+questionIndex+'][options]', JSON.stringify(question.options));

        question.options.forEach((option, optionIndex) => {
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][id]', option.id);
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][name]', option.name);
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][is_correct]', option.is_correct);
          if (question.type === 4 || question.type === 5) {
            formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][media]', option.media);
          }
        });
      });


      //formData.append("question_selection", self.selectedQuestions);
      formData.append("id", self.exam.id);
      formData.append("name", self.exam.name);
      formData.append("passmark", self.exam.passmark);
      formData.append("duration", self.exam.duration);
      formData.append("is_default", self.exam.is_default ? 1 : 0);
      formData.append("is_mix_questions", self.exam.is_mix_questions ? 1 : 0);
      formData.append("is_mix_answers", self.exam.is_mix_answers ? 1 : 0);
      formData.append("show_right_answers", self.exam.show_right_answers ? 1 : 0);
      formData.append("show_user_answers", self.exam.show_user_answers ? 1 : 0);
      formData.append("show_result", self.exam.show_result ? 1 : 0);
      formData.append("is_compulsory", self.exam.is_compulsory ? 1 : 0);

      if (validated) {

        store
          .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
            url: BASE_URL + "/" + self.$route.params.id,
            contents: formData,
            // returnType: "stateless",
            // acceptPromise: true,
            // {
            //   name: self.name,
            //   passmark: self.passmark,
            //   is_default: self.is_default,
            //   //question_selection: self.selectedQuestions
            // },
          });
      }

    },
    // saveForm() {
    //   let self = this;
    //   let validated = true;
    //
    //   if (self.exam.name == null || self.exam.name.length < 3) {
    //     this.errorMessages.push(
    //         self.$t("validation.required_field").replace(":NAME", self.$t("exam.name"))
    //     );
    //     self.scrollToTop();
    //     validated = false;
    //   }
    //
    //   if (self.exam.passmark == null || parseInt(self.exam.passmark) < 0 || parseInt(self.exam.name.length) > 100) {
    //     this.errorMessages.push(
    //         self.$t("validation.required_field").replace(":NAME", self.$t("exam.passmark"))
    //     );
    //     self.scrollToTop();
    //     validated = false;
    //   }
    //
    //   // if (self.exam.selected_questions.length === 0) {
    //   //   this.errorMessages.push(
    //   //       this.$t("validation.required_field").replace(
    //   //           ":NAME",
    //   //           self.$t("exam.question_type")
    //   //       )
    //   //   );
    //   //   self.scrollToTop();
    //   //   validated = false;
    //   // }
    //
    //   // let selectedQuestions = [];
    //   // this.selectedQuestions.forEach(item => {
    //   //   selectedQuestions.push(item.id)
    //   // });
    //
    //   if (validated) {
    //     store.dispatch(_MODULE_NAME + "/" + UPDATE_ITEM, {
    //       url: BASE_URL + "/" + self.$route.params.id,
    //       contents: {
    //         // question_selection: selectedQuestions,
    //         name: self.exam.name,
    //         is_default: self.exam.is_default,
    //         passmark: self.exam.passmark
    //       }
    //     });
    //   }
    //
    //
    // },

    filterResult() {
      let self = this;
      this.$store.dispatch(_MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL + "/" + this.$route.params.id,
      }).then((response) => {
        this.exam = response.data;
        this.setQuestions();
      })
    },
    // getQuestionName(id) {
    //   for (let i = 0; i < this.questions.length; i++) {
    //     if (this.questions[i].id == id) {
    //       return this.questions[i].text;
    //     }
    //   }
    // },
    // getQuestions() {
    //   this.$store.dispatch(QUESTIONS_MODULE_NAME + "/" + QUESTIONS_GET_ITEMS, {
    //     url: QUESTIONS_BASE_URL,
    //     filters: {
    //       for_select_options: true,
    //     },
    //   });
    // },
    resetMessages() {

    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    // this.getQuestions();
    this.error = [];
    this.filterResult();
  },
  watch: {
    success: function (val) {
      if (val) {
        this.successMessage = this.$t("general.successfully_updated");

        this.filterResult();

        this.$router.push({ name: "company.exams.list" });
      }
    },
    error: function (val) {
      if (val) {
        this.scrollToTop();
        this.errorMessages.push(val);
      }
    },
    // formatQuestionsItem() {
    //   setTimeout(() => {
    //     $(".selectpicker2").selectpicker();
    //   }, 600);
    // }
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 5000);
  },
};
</script>
<style scoped>
.selected_questions {
  color: #ae71ff;
  border: 1px solid #eaeaea;
  display: block;
  font-size: 13px;
  line-height: 33px;
  padding: 1px 7px;
  border-radius: 8px;
}
</style>
